

.recipeM-container p {
    font-family: 'Raleway', sans-serif;
    padding: 5px 20px;
    font-size: .9em;    color: black;
    margin: 5px 0;
}

.recipeM-container span{
    font-family: 'Vidaloka', serif;
    font-size: 20px;
    color: white;
}

.recipeM-container h2 {
    font-size: 2.3em;
}

@media (min-width: 800px) { 

    .recipeM-container p {
        font-family: 'Raleway', sans-serif;
        padding: 5px 50px;
        font-size: 17px;
        color: black;
        margin: 10px 0;
    }

    .recipeM-container span{
        font-family: 'Vidaloka', serif;
        font-size: 30px;
        color: white;
    }

    .recipeM-container h2 {
        margin-top: 10px;
        font-size: 3.2em;
    }
}