/* font-family: 'Cookie', cursive;
font-family: 'Vidaloka', serif;
font-family: 'Abel', sans-serif; */

main{
    height: 100vh;
}
.dashboard-container {
    padding-top: 55px;
    height: 100vh;
    margin-top: 0;
    overflow-y: auto;
    width: 100%;
}


.landing-section {
    margin-top: 0;
    height: 70vh;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    display: flex;
    flex-direction: column;
}

.dashhead {
    font-family: 'Raleway', sans-serif; 
    font-size: 30px;
    color: white;
    letter-spacing: 2px;
    margin: 0;
    padding-top: 10vh;
    padding-right: 10px;
    padding-left: 10px;
}
.dashhead span {
    color:rgba(114, 182, 105, 0.856);
}
.describution {
    font-family: 'Raleway', sans-serif; 
    color: white;
    font-size: 18px;
    padding-top: 50px;
    padding-right: 15px;
    padding-left: 15px;
}

.start {
    margin-left: 5px;
    background: rgba(92, 146, 85, 0.856);
    color: white;
    text-align: center;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    width: 150px;
    height: 35px;
    margin: 25px auto;
    border: white solid 1px;
    border-radius: 1em;
    padding: 2.5px;
}

.start:hover{
    color: rgba(92, 146, 85);
    background: white;
}

.dashboard-div{
    height: 300px;
    margin: 10px;
    background: rgba(92, 146, 85, 0.856);
    color: white;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.welcome {
    margin-top: 0;
    font-family: 'Cookie', cursive;
    font-size: 50px;
    color: white;
    padding-top: 80px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;
}



.dashboard-div p {
    font-family: 'Cookie', cursive;
    font-size: 40px;
    margin: 0;
    padding: 0;
}

.infiltLogo {
    background: url('../../img/app-ingre-icon.png');
    width: 90px;
    height: 115px;
    background-repeat: no-repeat;
    background-size: contain ;
    margin: 26px auto;


}

.timefiltLogo {
    background: url('../../img//app-timer-icon.png');
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain ;
    margin: 30px auto;
}

.allfiltLogo{
    background: url('../../img//app-book-icon.png');
    width: 113px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain ;
    margin: 30px auto;
}

.dashboard-div:hover{
    background: #5b9054;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    transition-duration: 0.4s;

}

@media (min-width: 500px) { 
    .dashhead {
        width: 500px;
        margin: 0 auto;
        font-size: 40px;
    }

    .describution {
        width: 500px;
        margin: 0 auto;
        font-size: 20px;
    }

    .welcome {
        font-size: 65px;
    }
}
@media (min-width: 800px) { 
    .dashhead {
        width: 600px;
        margin: 0 auto;
    }

    .describution {
        width: 560px;
        margin: 0 auto;
    }

    .dashboard-div p {
        font-family: 'Cookie', cursive;
        font-size: 40px;
        margin: 0;
        padding: 0;
    }


    .welcome {
        margin-top: 0;
        font-family: 'Cookie', cursive;
        font-size: 80px;
        color: white;
        padding-top: 80px;
        padding-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 0;
    }
    .options {
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 90%;

    }

    .get-started {
        height: 100vh;
    
    }
    .dashboard-div{
        width: 100%;
        height: 500px;

    }


    .dashboard-div p {
        font-family: 'Cookie', cursive;
        font-size: 34px;
        margin: 0;
        padding: 0;
    }




    .infiltLogo {
        background: url('../../img/app-ingre-icon.png');
        width: 30%;
        height: 30%;
        background-repeat: no-repeat;
        background-size: contain ;
        margin: 50px auto;


    }

    .timefiltLogo {
        background: url('../../img//app-timer-icon.png');
        width: 35%;
        height: 30%;
        background-repeat: no-repeat;
        background-size: contain ;
        margin: 50px auto;
    }

    .allfiltLogo{
        background: url('../../img//app-book-icon.png');
        width: 40%;
        height: 30%;
        background-repeat: no-repeat;
        background-size: contain ;
        margin: 50px auto;
    }

}
@media (min-width: 1000px) { 
    .dashhead {
        width: 800px;
        margin: 0 auto;
        padding-top: 20vh;

    }

    .describution {
        width: 750px;
        margin: 0 auto;
    }
}


@media (min-width: 1200px) { 

    .dashboard-div p {
        font-family: 'Cookie', cursive;
        font-size: 53px;

    }
}
