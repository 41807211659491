.filter-all-container {

    overflow-y: auto;
    height: 100vh;
    width: 100vw;

}

.filter-all-container h2 {
    color: white;
    margin-top: 55px;
    padding: 20px;
    font-family: 'Cookie', cursive;
    font-size: 60px;
}