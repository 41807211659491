* {box-sizing: border-box;}



body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('./img/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
main {

  height: 100vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.addButton {
  width: 100px;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
