/* font-family: 'Cookie', cursive;
font-family: 'Vidaloka', serif;
font-family: 'Abel', sans-serif;
font-family: 'Raleway', sans-serif; */

main {
    height: 100vh;
    overflow-y: auto;
}

.ingredFilt-container {
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    overflow-y: auto;
}

.left, .right {
    background: rgba(92, 146, 85);
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;

}
.left p, .right p {
    font-family: 'Cookie', cursive;
    font-size: 2.5em;
    margin: 20px 5px;
    color: white;
}
.left input {
    border: solid 1px grey;
    width: 200px;
    margin-top: 15px;
    height: 35px;
    border-radius: 5px;
    font-family: 'Raleway', sans-serif;
    font-size: 17px;
    padding-left: 15px;
}

.add-more-ingred, .search {
    margin-left: 5px;
    background: rgba(92, 146, 85, 0.856);
    color: white;
    text-align: center;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    border: 0px; 

}
.add-more-ingred {
    width: 250px;
    margin-top: 10px;
}

.search{
    display: block;
    width: 90px;
    height: 30px;
    margin: 25px auto;
    border: white solid 1px;
    border-radius: 1em;
}

.search:hover{
    color: rgba(92, 146, 85);
    background: white;
}


@media (min-width: 800px) { 

    .ingredFilt-container {
        margin: auto;
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    
    .left, .right {
        background: rgba(92, 146, 85);
        width: 48%;
        height: 75vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .left p, .right p {
        font-family: 'Cookie', cursive;
        font-size: 3em;
        margin: 20px 5px;
        color: white;
    }
    .left input {
        border: solid 1px grey;
        width: 18vw;
        margin-top: 15px;
        height: 35px;
        border-radius: 5px;
        font-family: 'Raleway', sans-serif;
        font-size: 17px;
        padding-left: 15px;
    }
    
    
    
}

@media (min-width: 1000px) { 
    .ingredFilt-container {
        margin: auto;
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    
    .left, .right {
        background: rgba(92, 146, 85);
        width: 40%;
        height: 75vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .left p, .right p {
        font-family: 'Cookie', cursive;
        font-size: 3em;
        margin: 20px 5px;
        color: white;
    }
    .left input {
        border: solid 1px grey;
        width: 18vw;
        margin-top: 15px;
        height: 35px;
        border-radius: 5px;
        font-family: 'Raleway', sans-serif;
        font-size: 17px;
        padding-left: 15px;
    }
    
    .add-more-ingred, .search {
        margin-left: 5px;
        background: rgba(92, 146, 85, 0.856);
        color: white;
        text-align: center;
        text-decoration: none;
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        cursor: pointer;
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        border: 0px; 
    
    }
    .add-more-ingred {
        width: 250px;
        margin-top: 10px;
    }
    
    .search{
        display: block;
        width: 90px;
        height: 30px;
        margin: 25px auto;
        border: white solid 1px;
        border-radius: 1em;
    }
    
    .search:hover{
        color: rgba(92, 146, 85);
        background: white;
    }

}